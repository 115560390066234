<template>
  <div class="bg-orange-50">
    <div
      class="flex flex-row justify-between items-center align-middle container mx-auto"
    >
      <div class="flex-1 flex flex-col items-center py-16">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-16 w-h-16 text-red-500"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fill-rule="evenodd"
            d="M3.172 5.172a4 4 0 015.656 0L10 6.343l1.172-1.171a4 4 0 115.656 5.656L10 17.657l-6.828-6.829a4 4 0 010-5.656z"
            clip-rule="evenodd"
          />
        </svg>
        <h2 class="font-bold text-5xl max-w-xl">
          Encuentra a tú próximo mejor amigo
        </h2>
        <p class="text-lg text-gray-600 max-w-xl mt-4">
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Non deserunt
          et aliquam, quis labore reprehenderit fugit mollitia reiciendis
          molestiae quod vitae ad.
        </p>
      </div>
      <div class="flex-1 lg:flex justify-center hidden">
        <img
          class="rounded-2xl my-8"
          src="../../assets/erin_minuskin_swjiqc_PuCHl.jpg"
          alt="erin_minuskin_swjiqc_PuCHl.jpg"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HomeLanding'
}
</script>

<style></style>
