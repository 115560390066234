<template>
  <div id="home-layout">
    <home-landing></home-landing>
    <home-showcase></home-showcase>
    <home-about></home-about>
  </div>
</template>

<script>
import HomeLanding from '../components/home-landing/HomeLanding.vue'
import HomeShowcase from '../components/home-showcase/HomeShowcase.vue'
import HomeAbout from '../components/home-about/HomeAbout.vue'

export default {
  name: 'Home',
  components: {
    HomeLanding,
    HomeShowcase,
    HomeAbout
  },
  data() {
    return {
      active: false
    }
  }
}
</script>
