<template>
  <div id="home">
    <home-layout></home-layout>
  </div>
</template>

<script>
import HomeLayout from '../layout/HomeLayout.vue'

export default {
  name: 'Home',
  components: {
    HomeLayout
  }
}
</script>
