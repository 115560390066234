<template>
  <div class="container mx-auto my-16">
    <div class="flex items-center mx-2 md:mx-0">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="h-6 w-6 mb-1 text-gray-500"
        viewBox="0 0 20 20"
        fill="currentColor"
      >
        <path
          d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"
        />
      </svg>
      <span class="font-semibold text-2xl mx-2">Buscando un hogar</span>
    </div>
    <div
      class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8 mt-4 mx-2 md:mx-0"
    >
      <home-showcase-card
        image-uri="kelly_newsom_ccp9j2n_cAQRk.jpg"
      ></home-showcase-card>
      <home-showcase-card
        image-uri="ivana_la_tyczhr54ddk_4WwPr.jpg"
      ></home-showcase-card>
      <home-showcase-card
        image-uri="kari_shea_film6ng7vg_IwJcW.jpg"
      ></home-showcase-card>
      <home-showcase-card
        image-uri="alvan_nee_brfsz7qszs_0SgTv.jpg"
      ></home-showcase-card>
      <home-showcase-card
        image-uri="michael_sum_lepfefqf_pDe2N.jpg"
      ></home-showcase-card>
      <home-showcase-card
        image-uri="sergey_semin_vqpd1fc_Z36R5.jpg"
      ></home-showcase-card>
      <home-showcase-card
        image-uri="aut_eknscnzpk6s_unsp_STCZo.jpg"
      ></home-showcase-card>
      <home-showcase-card
        image-uri="manja_vitolic_gkxkby_Gwk1A.jpg"
      ></home-showcase-card>
    </div>
  </div>
</template>

<script>
import HomeShowcaseCard from '../home-showcase-card/HomeShowcaseCard.vue'

export default {
  components: {
    HomeShowcaseCard
  },
  name: 'HomeShowcase'
}
</script>
