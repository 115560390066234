<template>
  <div id="home-showcase-card" class="flex flex-col rounded-lg shadow-lg">
    <img
      class="min-h-72 h-72 max-h-72 object-cover object-center rounded-t-lg"
      :src="require(`@/modules/home/assets/${imageUri}`)"
      :alt="imageUri"
    />
    <div class="bg-gray-50 rounded-b-lg p-4">
      <h5 class="font-semibold text-lg">Lorem ipsum dolor sit amet.</h5>
      <p class="text-base text-gray-600 mt-2">
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Ex assumenda
        nobis laboriosam illum, nam odio cumque blanditiis quia dolore? Cumque
        natus culpa, iusto expedita aperiam.
      </p>
      <button
        class="flex justify-center bg-orange-100 hover:bg-orange-200 text-orange-500 hover:text-orange-400 text-center font-medium duration-200 w-full rounded-md p-2 mt-4"
      >
        <span>Ver más</span>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HomeShowcaseCard',
  props: {
    imageUri: {
      type: String,
      default: ''
    }
  }
}
</script>

<style></style>
